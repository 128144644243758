@font-face {
    font-family: skribble;
    src: url(../public/Skribble.ttf);
}

body {
    font-family: skribble;
    color: white;
    overflow-x: hidden;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #ff9900;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #ff9900ab;
}