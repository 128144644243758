.share{
    width: 100%;
    margin: 20px 0;
}

.share-container {
    max-width: 1323px;
    margin: 0 auto;
}

.share-container__title{
    color: #f90;
    margin-bottom: 10px;
}
.share-container-top{
    padding: 10px;
}

.share-container-bottom{
    padding: 10px;
}

.story-input__text{
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    border: 1px solid #f90;
    border-radius: 5px;
    background-color: black;
    color: #f90;
    font-family: Skribble;
}

.story-post-button__button{

    background-color: #f90;
    color: black;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: Skribble;
}

.story{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.story-item{
    width: 90;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #f90;
    border-radius: 5px;
    background-color: black;
    font-family: Skribble;
}