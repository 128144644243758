.home-div {
  width: 100%;
  margin: 20px 0 20px 0;
}

.home-container {
  max-width: 1323px;
  margin: 0 auto;
}

.home-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #f90;
  margin: 0 0 20px 0;
}

.line1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  color: #f90;
}

.cornhub {
  font-weight: bolder;
}

.hot-videos {
  display: flex;
  gap: 10px;
  margin: 10px;
}

.videos {
  max-width: 1323px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
}

.placeholder {
  opacity: 0.6;
}

.placeholder-img {
  width: 100%;
  height: 180px;
  background-color: #0e0e0e;
  border-radius: 10px 10px 0 0;
}

.placeholder-img-small {
  width: 24px;
  height: 24px;
  background-color: #ccc;
  border-radius: 50%;
}

.placeholder-text {
  background-color: #eee;
  color: transparent;
  border-radius: 4px;
  height: 16px; /* Adjust as needed */
  margin: 4px 0; /* Adjust as needed */
}

/* Add your existing CSS styles */

.line2-right-bottom {
  position: relative;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  bottom: 30px;
  right: 0;
  background-color: #333;
  color: #f90;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  z-index: 10;
}

.tooltip button {
  background-color: #f90;
  color: #333;
  border: none;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  margin: 5px;
  transition: border 0.3s, color 0.3s, background-color 0.3s;
}

.tooltip button:hover {
  border: 1px solid #f90;
  color: #f90;
  background-color: #333;
}


.line {
  display: flex;
  justify-content: space-between;
}

.line-left {
  display: flex;
  gap: 5px;
}

.line-right {
  display: flex;
  gap: 10px;
}

.home-box-top img {
  width: 100%;
  height: auto;
  margin-bottom: 0;
}


.home-box-bottom {
  margin-top: -30px;
}

p {
  margin: 0;
}


.panel-socials {
  display: flex;
  align-items: center;
  gap: 30px;
  
}

.panel-socials img {
  transition: transform 1s;
}

.panel-socials img:hover {
  transform: translateY(-3px);
}



.clickable-div {
  display: inline-block;
  width: 200px;
  height: 150px;
  margin: 10px;
  cursor: pointer;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clickable-div:hover {
  transform: scale(1.05);
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
  background-color: #000000;
  padding-top: 25px;
  margin: 0;
  width: 50%;
  height: 541px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}

.line2 {
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  row-gap: 10px;
}

.line2-left {
  margin: 0 0 0 10px;
  display: flex;
  align-items: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 1px
}

.line2-right {
  margin: 10px;
  display: flex;
  flex-direction: column;
  gap: 1px 20px;
}

.line2-right-top {
  display: flex;
  gap: 10px;
  align-items: center;
  transition: transform 1s;
}

.line2-right-top:hover {
  transform: translateY(-3px);
}

.line2-right-bottom {
  transition: transform 1s;
}

.line2-right-bottom:hover {
  transform: translateY(-3px);
}

.close {
  color: #aaa;
  border: white 1px solid;
  padding: 5px;
  border-radius: 15px;
}

.close:hover,
.close:focus {
  color: #ff9900;
  text-decoration: none;
}

.line3 {
  margin: 0 20px 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

@media screen and (max-width: 768px) {
  .home-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    color: #f90;
    margin: 0 0 20px 0;
  }

  .videos {
    max-width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }

  .modal-content {
    background-color: #000000;
    padding-top: 25px;
    margin: 0;
    width: 95%;
    height: 541px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
  }

  #title {
    font-size: 20px;
  }

  #creator {
    font-size: 10px;
  }

  #like {
    font-size: 15px;
  }

  #like_button {
    width: 15px;
    height: 15px;
  }

  .close {
    margin-top: -16px;
  }

  .line2-right-bottom {
    font-size: 15px;
  }
}

.footer-text{
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
}