.memefarm {
    width: 100%;
    margin-top: 25px;
}

.memefarm-container {
    max-width: 1323px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.generate {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.generate-text{
    color: #f90;
    font-size: 40px;
    border: solid 1px #f90;
    padding: 10px;
    user-select: none;
    border-radius: 25px;
    text-align: center;
    text-shadow: 3px 3px 10px rgba(255, 153, 0, 1),
             6px 7px 3px rgba(107, 18, 98, 0.72);
}

.collection {
    max-width: 1323px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 1rem;
}

.image-box img {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border: 1px solid #ff9900;
}

.generate-button{
    color: #f90;
    font-size: 40px;
    user-select: none;
}

.generated-image {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border: 1px solid #ff9900;
    border-radius: 15px;
}

.generated-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.heading {
    margin: 20px 0;
    font-size: large;
    color: #f90;
}

@media (max-width: 768px) {
    .collection {
        grid-template-columns: repeat(3, 1fr)
    }
    .generate{
        flex-direction: column;
    }
    .generate-text{
        font-size: 30px;
        margin-bottom: 15px;
    }
    .generate-button{
        display: none;
    }
}