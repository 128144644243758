

body {
    font-family: skribble;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
}


.warning {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
}

.warning-content {
    background-color: #000000;
    color: #fff;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 0 40px 5px rgba(255,255,255,.15);
    max-width: 600px;
    width: 100%;
    box-sizing: border-box;
    border-color: #2e2e2e;
    border-style: solid;
    border-width: 1px;
    font-family: skribble;
}

.title{
    display: flex;
    justify-content: center;
}

.hub{
    color: black;
    background: #ff9900;
    border-radius: 5px;
}

.warning-content h2 {
    margin: 0 0 10px;
    color: #fff;
    font-size: 1.5em;
}

.warning-content p {
    margin: 10px 0;
    font-size: 1em;
    letter-spacing: 2px;
    color: #ccc;
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 20px 0;
}

.confirm-button, .exit-button {
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1em;
    border: none;
}

.confirm-button {
    background-color: #ff9900;
    color: #000000;
}

.exit-button {
    background-color: #1f1f1f;
    color: #fff;
}

.confirm-button:hover {
    background-color: #ff990080;
    color: #f4f4f4;
}

.exit-button:hover {
    background-color: #333;
}

.link {
    color: #ffcc00;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}

@media (max-width: 600px) {
    .warning-content {
        padding: 15px;
    }

    .warning-content h2 {
        font-size: 1.2em;
    }

    .warning-content p {
        font-size: 0.9em;
    }

    .confirm-button, .exit-button {
        padding: 8px 16px;
        font-size: 0.9em;
    }
}

@media (max-width: 400px) {
    .warning-content {
        padding: 10px;
    }

    .warning-content h2 {
        font-size: 1em;
    }

    .warning-content p {
        font-size: 0.8em;
    }

    .confirm-button, .exit-button {
        padding: 6px 12px;
        font-size: 0.8em;
    }
}

@font-face {
    font-family: skribble;
    src: url(../../../public/Skribble.ttf);
}